/* MyComponent.module.css */
.gradient-text {
  background-image: linear-gradient(
    to right,
    blue,
    #7a49a5,
    rgb(143, 25, 25),
    green,
    #7a49a5
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-size: 26px;
  align-items: center;
  text-align: center;
  font-family: "Lilita One", sans-serif;
  font-weight: 400;
  font-style: normal;
}
